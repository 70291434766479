import React from "react";
import { Helmet } from "react-helmet";

class TermsOfUse extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Terms of Use - Smarter Technologist</title>
                    <meta name="description" content="Terms of use for the Smarter Technologist services." />
                    <link rel="canonical" href="https://smartertechnologist.com/terms-of-use" />
                </Helmet>
                <div className="container px-5 my-5 d-flex flex-column min-vh-100">
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-8 col-xl-6">
                            <h1 className="mt-4">Terms of Use</h1>
                            <p className="lead">Effective <span>15 January 2025</span></p>
                            <div className="lead fw-normal text-muted mb-5">
                                <span>Welcome to Smarter Technologist. By using our services, you agree to these terms of use. Please read them carefully.</span>
                            </div>

                            <div style={{ marginTop: -30, marginBottom: 10 }} className="fw-normal mb-5">
                                <h6>1. Acceptance of Terms</h6>
                                <p>
                                    <span>By accessing or using our applications or services, you agree to comply with and be bound by these terms. If you do not agree, please discontinue use immediately.</span>
                                </p>

                                <h6>2. User Responsibilities</h6>
                                <p>
                                    <span>Users are responsible for maintaining the confidentiality of their accounts and passwords. You agree not to use our services for any unlawful purposes or activities.</span>
                                </p>

                                <h6>3. Intellectual Property</h6>
                                <p>
                                    <span>All content, trademarks, and data on this site, including but not limited to software, text, graphics, and logos, are the property of Smarter Technologist and protected by applicable laws.</span>
                                </p>

                                <h6>4. Limitation of Liability</h6>
                                <p>
                                    <span>We are not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services.</span>
                                </p>

                                <h6>5. Modifications to Terms</h6>
                                <p>
                                    <span>We may revise these terms from time to time. Continued use of our services constitutes acceptance of such changes.</span>
                                </p>

                                <h6>6. Termination</h6>
                                <p>
                                    <span>We reserve the right to terminate or suspend your access to our services at any time, without notice, for conduct that we believe violates these terms.</span>
                                </p>

                                <h6>7. Governing Law</h6>
                                <p>
                                    <span>These terms are governed by the laws of [Insert Jurisdiction], and any disputes will be resolved in the courts of this jurisdiction.</span>
                                </p>

                                <h6>Contact Us</h6>
                                <p>
                                    <span>If you have any questions about these terms, please contact us at smartertechnologist @t gmail.com.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsOfUse;
