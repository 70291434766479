import React from "react";

import "./footer.css"

const style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    marginTop: "-45px",
    padding: "15px",
    position: "relative",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
};

const phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
    marginTop: -100,
};

const Footer = () => {
    return (
        // <div>
        //     <div style={phantom} />
        //     <div style={style} className={"bg-dark py-4 mt-auto"}>
        //         <div className={"container"}>
        //             <div className="row align-items-center justify-content-between flex-column flex-sm-row">
        //                 <div className="col-auto text-center text-white">
        //                     <p>
        //                         &copy; {(new Date().getFullYear())} Smarter Technologist
        //                     </p>
        //                 </div>
        //                 <div className="col-auto">
        //                     <a className="link-light small" href="/privacy">Privacy</a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <footer className="bg-dark py-4 mt-auto">
            <div className="container px-5">
                <div className="row align-items-center justify-content-between flex-column flex-sm-row">
                    <div className="col-auto">
                        <div className="small m-0 text-white">Copyright © Smarter
                            Technologist {(new Date().getFullYear())}</div>
                    </div>
                    <div className="col-auto">
                        <a className="link-light small" href="/privacy">Privacy</a>
                        <span className="text-white mx-1">·</span>
                        <a className="link-light small" href="/terms-of-use">Terms</a>
                        <span className="text-white mx-1">·</span>
                        <a className="link-light small" href="/smarterbookmarks/privacy">Smarter Bookmarks Privacy</a>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;
