import React from "react";
import { Helmet } from "react-helmet";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Privacy Policy - Smarter Bookmarks</title>
          <meta
            name="description"
            content="Learn about our app's privacy practices and features like Cloud Sync and data synchronization."
          />
          <link rel="canonical" href="https://smartertechnologist.com/smarterbookmarks/privacy" />
        </Helmet>
        <div className="container px-5 my-5 d-flex flex-column min-vh-100">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <h1 className="mt-4">Privacy Policy - Smarter Bookmarks</h1>
              <p className="lead">Updated: 15 January, 2025</p>
              <p className="fw-normal text-muted mb-5">
                Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information 
                when you use the Smarter Bookmarks app and related services. By using the Smarter Bookmarks app, you agree to the 
                practices described in this policy.
              </p>


              <p style={{ marginTop: -30, marginBottom: 10 }}><b><span>Information We Collect</span></b></p>
              <ul>
                <li>
                  <b>Personal Information:</b> We may collect your email address or similar identifiers when you sign
                  up for certain features, such as syncing or support.
                </li>
                <li>
                  <b>Automatically Collected Information:</b> This includes device type, operating system, app
                  version, and anonymized usage data.
                </li>
                <li>
                  <b>Data for Syncing Sources:</b> When you connect external sources like Google Drive or RSS feeds,
                  we may process limited data to facilitate syncing.
                </li>
              </ul>

              <p><b><span>How We Use Your Information</span></b></p>
              <p>We use the collected information for the following purposes:</p>
              <ul>
                <li>To provide, maintain, and improve our app and services.</li>
                <li>To synchronize data across your devices using Cloud Sync or other sources.</li>
                <li>To notify you of updates, changes, or service interruptions.</li>
              </ul>

              <p><b><span>Cloud Sync Feature</span></b></p>
              <p>
                Our app includes a <b>Cloud Sync</b> feature to synchronize your data across devices securely by
                uploading backups to a private app folder in your Google Drive. This feature is designed to improve your
                experience by providing seamless access to your data across multiple devices.
              </p>
              <ul>
                <li>
                  <b>Data Handling:</b> The data uploaded to Google Drive is stored in a private app-specific folder
                  and is not accessible to anyone without proper authorization.
                </li>
                <li>
                  <b>Authorization:</b> You must grant permission to access your Google Drive. This permission is
                  limited to managing backups within the designated app folder.
                </li>
                <li>
                  <b>User Control:</b> You can disable Cloud Sync at any time and delete backups from your Google Drive
                  manually.
                </li>
              </ul>

              <p><b><span>Syncing Sources</span></b></p>
              <p>
                Our app allows syncing with external sources like Google Drive folders or RSS feeds. When you enable
                these features:
              </p>
              <ul>
                <li>
                  <b>Access Permissions:</b> We only access the specific files or feeds you authorize for syncing.
                </li>
                <li>
                  <b>Data Privacy:</b> No data from external sources is stored permanently unless explicitly chosen by
                  the user.
                </li>
              </ul>

              <p><b><span>Data Retention Policy</span></b></p>
              <p>
                We retain automatically collected information for up to 24 months, after which it may be aggregated or
                anonymized for analytical purposes.
              </p>

              <p><b><span>Children's Privacy</span></b></p>
              <p>
                We do not knowingly collect data from children under the age of 13. If a parent or guardian becomes
                aware that their child has provided us with personal information, please contact us at{' '}
                <a href="mailto:smartertechnologist @t gmail.com">smartertechnologist @t gmail.com</a>. We will take steps to
                delete the information promptly.
              </p>

              <p><b><span>Changes to This Privacy Policy</span></b></p>
              <p>
                This Privacy Policy may be updated periodically. Any significant changes will be notified through the
                app or our website. Continued use of the services after such changes constitutes your acceptance.
              </p>

              <p><b><span>Your Consent</span></b></p>
              <p>
                By using our applications and services, you consent to the collection, use, and processing of your
                information as described in this Privacy Policy.
              </p>

              <p><b><span>Contact Us</span></b></p>
              <p>
                If you have questions or concerns about this Privacy Policy, please contact us at:{' '}
                <a href="mailto:smartertechnologist @t gmail.com">smartertechnologist @t gmail.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
