import React from "react";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./components/home/Home";
import NotFound from "./components/notfound/NotFound";
import BootstrapNavbar from "./components/navbar/BootstrapNavbar";
import Blog from "./components/blog/Blog";
import BlogPost from "./components/blog/blog-post/BlogPost";
import NewsItems from "./components/blog/news/News";
import News from "./components/blog/news/NewsItem";
import AboutUs from "./components/AboutUs";
import Privacy from "./components/Privacy";
import SBPrivacy from "./components/SBPrivacy";
import Terms from "./components/Terms";


function App() {

    return (
        <div className="App">
            <BootstrapNavbar/>

            <Router>
                <Switch>
                    <Route exact path={["/", "/home"]}>
                        <Home/>
                    </Route>
                    <Route exact path={"/about"}>
                        <AboutUs/>
                    </Route>
                    <Route exact path={"/terms-of-use"}>
                        <Terms/>
                    </Route>
                    <Route exact path={"/privacy"}>
                        <Privacy/>
                    </Route>
                    <Route exact path={"/smarterbookmarks/privacy"}>
                        <SBPrivacy/>
                    </Route>
                    <Route exact path={"/blog"}>
                        <Blog/>
                    </Route>
                    <Route exact path={"/stories"}>
                        <Blog/>
                        {/*TODO: Add Stories page*/}
                    </Route>
                    <Route exact path="/blog/:endpoint">
                        <BlogPost/>
                    </Route>
                    <Route exact path={"/news"}>
                        <NewsItems/>
                    </Route>
                    <Route exact path="/news/:endpoint">
                        <News/>
                    </Route>
                    <Route component={NotFound}/>
                </Switch>
            </Router>

            <Footer/>
        </div>
    );
}

export default App;